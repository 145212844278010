<template>
    <base-card class="min-w-[300px]">
        <div class="flex w-full flex-col gap-6">
            <div class="flex flex-col gap-2 text-sm font-normal text-gray-700">
                <p class="text-sm text-gray-500">{{ $t('general.address') }}</p>
                <div
                    v-if="addresses && addresses.length > 0"
                    class="flex flex-col gap-2"
                >
                    <div
                        v-for="address in addresses"
                        :key="address.id"
                        class="flex items-start justify-between"
                    >
                        <div class="overflow-auto">
                            <p class="truncate">
                                {{ $t('general.billingAddress') }}
                            </p>
                            <p class="truncate">{{ address?.street }}</p>
                            <p class="truncate">
                                {{ address?.city }} {{ address?.postcode }}
                            </p>
                            <p class="truncate">{{ address?.country?.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col gap-2.5 text-sm font-normal text-gray-700"
            >
                <p class="text-sm text-gray-500">
                    {{ $t('general.defaultCurrency') }}
                </p>

                <span>{{ contact.currency.iso }}</span>
            </div>

            <div
                v-if="contact.payment_term"
                class="flex flex-col gap-2.5 text-sm font-normal text-gray-700"
            >
                <p class="text-sm text-gray-500">
                    {{ $t('general.paymentTermsLabel') }}
                </p>

                <span>
                    {{ $t(`general.paymentTerms.${contact.payment_term}`) }}
                </span>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-end">
                <div
                    v-if="$acl.can('update_contacts')"
                    class="cursor-pointer text-sm text-primary-700"
                    @click="onEdit"
                >
                    {{ $t('general.updateInfo') }}
                </div>
            </div>
        </template>
    </base-card>
</template>

<script setup>
const props = defineProps({
    contact: {
        type: Object,
        required: true,
    },
})

const router = useRouter()
const addresses = computed(() => props.contact.contact_addresses)

const onEdit = () => {
    router.push({ name: 'contacts.edit', params: { id: props.contact.id } })
}
</script>
