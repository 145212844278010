<template>
    <base-section :title="$t('contacts.form.defaultSettings.title')">
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <form-single-select
                        v-model="currency"
                        :error-message="errorCurrency"
                        :label="$t('general.currency')"
                        :options="currencies"
                        option-value="value"
                        option-label="iso"
                        :searchable-fields="['iso', 'label']"
                        :disabled="!!disableCurrency"
                    />
                </form-control-group-row>
            </form-control-group>

            <hr class="my-10" />

            <div>
                <p class="mb-3.5 font-semibold text-gray-900">
                    {{ $t('contacts.form.defaultSettings.salesDefaults') }}
                </p>
                <p class="mb-[29px] text-sm text-gray-900">
                    {{
                        $t(
                            'contacts.form.defaultSettings.salesDefaultsDescription'
                        )
                    }}
                </p>

                <form-control-group>
                    <form-control-group-row>
                        <form-single-select
                            v-model="salesTaxRule"
                            :error-message="errorSalesTaxRule"
                            :label="$t('contacts.form.defaultSettings.taxRule')"
                            :options="salesTaxRules"
                            option-value="id"
                            :option-label="customTaxLabel"
                            :disabled="disabled"
                        />
                    </form-control-group-row>

                    <form-control-group-row>
                        <form-single-select
                            v-model="salesPaymentTerm"
                            :error-message="errorSalesPaymentTerm"
                            :label="
                                $t('contacts.form.defaultSettings.paymentTerms')
                            "
                            :options="paymentTerms"
                            :disabled="disabled"
                        />
                    </form-control-group-row>
                </form-control-group>
            </div>

            <hr class="my-10" />

            <div>
                <p class="mb-3.5 font-semibold text-gray-900">
                    {{ $t('contacts.form.defaultSettings.purchasesDefaults') }}
                </p>
                <p class="mb-[29px] text-sm text-gray-900">
                    {{
                        $t(
                            'contacts.form.defaultSettings.purchasesDefaultsDescription'
                        )
                    }}
                </p>

                <form-control-group>
                    <form-control-group-row>
                        <form-single-select
                            v-model="purchasesTaxRule"
                            :error-message="errorPurchasesTaxRule"
                            :label="$t('contacts.form.defaultSettings.taxRule')"
                            :options="purchasesTaxRules"
                            option-value="id"
                            :option-label="customTaxLabel"
                            :disabled="disabled"
                        />
                    </form-control-group-row>

                    <form-control-group-row>
                        <form-single-select
                            v-model="purchasesPaymentTerm"
                            :error-message="errorPurchasesPaymentTerm"
                            :label="
                                $t('contacts.form.defaultSettings.paymentTerms')
                            "
                            :options="paymentTerms"
                            :disabled="disabled"
                        />
                    </form-control-group-row>
                </form-control-group>
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { PAYMENT_TERMS } from '@tenant/utils/constants'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { currencies } = useCurrencies()
const { taxRules } = useTaxRules()

const purchasesTaxRules = computed(() => {
    return taxRules.value
        ? taxRules.value.filter((rule) => rule.category === 'purchases')
        : []
})

const salesTaxRules = computed(() => {
    return taxRules.value
        ? taxRules.value.filter((rule) => rule.category === 'sales')
        : []
})

const { value: currency, errorMessage: errorCurrency } = useField('currency_id')
const { value: disableCurrency } = useField('disable_currency')
const { value: salesTaxRule, errorMessage: errorSalesTaxRule } =
    useField('sales_tax_rule_id')
const { value: salesPaymentTerm, errorMessage: errorSalesPaymentTerm } =
    useField('sales_payment_term')
const { value: purchasesTaxRule, errorMessage: errorPurchasesTaxRule } =
    useField('purchases_tax_rule_id')
const { value: purchasesPaymentTerm, errorMessage: errorPurchasesPaymentTerm } =
    useField('purchases_payment_term')

const customTaxLabel = (option) => `${option.name} (${option.tax_rate})`

const { t } = useI18n()
const paymentTerms = computed(() => {
    return Object.values(PAYMENT_TERMS).map((item) => ({
        value: item,
        label: t(`general.paymentTerms.${item}`),
    }))
})
</script>
