<template>
    <base-section
        :title="$t('contacts.form.addressSection.title')"
        :description="$t('contacts.form.addressSection.description')"
    >
        <template #right>
            <form-field-array
                :fields="fields"
                :label-add="$t('contacts.form.addressSection.addAddress')"
                @on-remove-item="remove"
                @on-add-item="onAddItem"
            >
                <template #default="{ item: { key, value } }">
                    <form-control-group with-space class="grow">
                        <form-control-group-row>
                            <form-field-array-error
                                :name="`contact_addresses[${key}].street`"
                                #="{ errorMessage }"
                            >
                                <form-text-input
                                    v-model="value.street"
                                    label="contacts.form.addressSection.address.street"
                                    :error-message="errorMessage"
                                    :disabled="disabled"
                                />
                            </form-field-array-error>
                        </form-control-group-row>
                        <form-control-group-row>
                            <form-field-array-error
                                :name="`contact_addresses[${key}].postcode`"
                                #="{ errorMessage }"
                            >
                                <form-text-input
                                    v-model="value.postcode"
                                    label="contacts.form.addressSection.address.postcode"
                                    :error-message="errorMessage"
                                    :disabled="disabled"
                                />
                            </form-field-array-error>
                            <form-field-array-error
                                :name="`contact_addresses[${key}].city`"
                                #="{ errorMessage }"
                            >
                                <form-text-input
                                    v-model="value.city"
                                    label="contacts.form.addressSection.address.city"
                                    :error-message="errorMessage"
                                    :disabled="disabled"
                                />
                            </form-field-array-error>
                        </form-control-group-row>
                        <form-control-group-row>
                            <form-field-array-error
                                :name="`contact_addresses[${key}].country_id`"
                                #="{ errorMessage }"
                            >
                                <form-single-select
                                    v-model="value.country_id"
                                    label="contacts.form.addressSection.address.country"
                                    :options="countries"
                                    :error-message="errorMessage"
                                    :disabled="disabled"
                                />
                            </form-field-array-error>
                            <form-field-array-error
                                :name="`contact_addresses[${key}].contact_address_type_id`"
                                #="{ errorMessage }"
                            >
                                <form-single-select
                                    v-model="value.address_type_id"
                                    label="general.type"
                                    :options="addressTypes"
                                    :error-message="errorMessage"
                                    :disabled="disabled"
                                />
                            </form-field-array-error>
                        </form-control-group-row>
                    </form-control-group>
                </template>
            </form-field-array>
        </template>
    </base-section>
</template>

<script setup>
import { useFieldArray } from 'vee-validate'
import { useCountries, useAddressTypes } from '@tenant/composables'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { countries } = useCountries()
const { remove, push, fields } = useFieldArray('contact_addresses')
const { addressTypes } = useAddressTypes()

const onAddItem = () => {
    push({
        street: null,
        postcode: null,
        city: null,
        country_id: null,
    })
}
</script>
