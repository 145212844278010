<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('contacts.title') }}
                    </div>
                    <base-badge
                        :label="`${contacts?.total || 0} ${$t(
                            'contacts.title'
                        )}`"
                    />
                </div>
                <div class="text-sm font-normal text-gray-500">
                    {{ $t('contacts.description') }}
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <!-- TODO AC-504: Add "Import Contact" functionality: https://shapeandshift.atlassian.net/browse/AC-504 -->

                <!-- <base-button
                    size="md"
                    variant="default"
                    icon="line-icons:general:upload-cloud-02"
                >
                    {{ $t('general.import') }}
                </base-button> -->
                <base-button
                    v-if="$acl.can('create_contacts')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="redirectToCreate"
                >
                    {{ $t('contacts.addContact') }}
                </base-button>
            </div>
        </template>

        <data-grid
            v-if="defaultCurrency"
            :data-source="contacts.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('contacts.emptyText')"
            :empty-sub="$t('contacts.emptySubText')"
            identify-field="id"
            @row-click="redirectToDetail"
            table-borderless
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <contacts-filter-views />
                    </div>
                    <div class="flex grow gap-x-3 gap-y-1">
                        <contacts-filter-search />
                        <contacts-filter-slide-out />
                    </div>
                </div>
            </template>

            <template #column-name="{ item }">
                <contacts-title-group :item="item" :show-icon="false" />
            </template>

            <template #column-type="{ item }">
                {{ item?.contact_type?.name }}
            </template>

            <template #column-phone="{ item }">
                {{ item.phone && item.phone[0] && item.phone[0].phone }}
            </template>

            <template #column-receivables="{ item }">
                {{
                    $filters.currency(
                        item.receivables,
                        item.currency?.iso ?? defaultCurrency.iso
                    )
                }}
            </template>

            <template #column-payables="{ item }">
                {{
                    $filters.currency(
                        item.payables,
                        item.currency?.iso ?? defaultCurrency.iso
                    )
                }}
            </template>

            <template #column-action="{ item }">
                <contacts-context-menu
                    @click-edit="redirectToEdit(item)"
                    @click-delete="onDelete(item)"
                />
            </template>

            <template #footer>
                <grid-pagination :total="contacts.last_page" :page="1" />
            </template>

            <template v-if="!hasFilter" #empty-actions>
                <div class="flex gap-3">
                    <base-button
                        size="md"
                        variant="primary"
                        icon="line-icons:general:plus"
                        @click="redirectToCreate"
                    >
                        {{ $t('contacts.addContact') }}
                    </base-button>
                </div>
            </template>
        </data-grid>
    </base-sticky-heading>

    <base-delete-modal
        :loading="loadingDelete"
        :show="deletingContact?.id && !cannotDelete"
        :name="deletingContact?.name"
        :confirmation-message="$t('contact.list.deleteConfirmation')"
        :confirmation-value="deletingContact?.name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />

    <contacts-cannot-delete-modal
        :contact="deletingContact"
        :show="cannotDelete"
        @modal-close="onCannotDeleteModalClose"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useApiFactory, useNotification } from '@tenant/composables'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { successNotify } = useNotification()
const { defaultCurrency } = useCurrencies()

useHead({
    title: t('contacts.title'),
})

const {
    entities: contacts,
    entity: deletingContact,
    setEntity,
    useApiSearch,
    useApiDelete,
} = useApiFactory('contacts')
const { loading: loadingSearch, execute: executeSearch } = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const columns = [
    {
        property: 'customer_number',
        label: t('contacts.list.customerNo'),
    },
    {
        property: 'name',
        label: t('contacts.list.contactPerson'),
    },
    {
        property: 'type',
        label: t('contacts.list.type'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'phone',
        label: t('contacts.list.phoneNumber'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'receivables',
        label: t('contacts.list.receivables'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'payables',
        label: t('contacts.list.payables'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
]

const confirmDelete = () => {
    executeDelete().then(() => {
        successNotify({
            title: t('general.success'),
            text: t('contacts.list.deleteSuccess'),
        })

        executeSearch()
    })
}

const redirectToCreate = () => {
    router.push({
        name: 'contacts.add',
    })
}

const redirectToEdit = (item) => {
    router.push({ name: 'contacts.edit', params: { id: item.id } })
}

const redirectToDetail = (id) => {
    router.push({ name: 'contacts.detail', params: { id } })
}

const cannotDelete = ref(false)
const onDelete = (item) => {
    setEntity(item)
    cannotDelete.value = item?.invoices?.length > 0
}

const onCannotDeleteModalClose = () => {
    cannotDelete.value = false
    setEntity(null)
}

const hasFilter = computed(() => route.query.q || route.query.filter)
</script>
