<template>
    <base-sticky-heading
        :title="$t('contacts.edit.title')"
        :description="$t('contacts.edit.description')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingSavePerson || loadingSaveOrg"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-6">
            <contacts-section-general :disabled="loadingGet" />
            <contacts-section-tabs />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useForm } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'
import { useEmitter, useApiFactory, useNotification } from '@tenant/composables'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()

const { successNotify } = useNotification()

const { setValues, setErrors, handleSubmit } = useForm()
const { useApiGet } = useApiFactory('contacts')
const { loading: loadingGet, execute: executeGet } = useApiGet()
const { loading: loadingSavePerson, execute: executeSavePerson } =
    useApiFactory('contacts/person').useApiSave()
const { loading: loadingSaveOrg, execute: executeSaveOrg } = useApiFactory(
    'contacts/organisation'
).useApiSave()

useHead({ title: t('contacts.edit.title') })

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const contact = await executeGet(route.params.id)
        setValues({
            ...contact,
            name: contact?.organisation?.name,
            contact_type: contact?.organisation_id
                ? CONTACT_RELATIONSHIP_TYPES.organisation
                : CONTACT_RELATIONSHIP_TYPES.person,
            salutation_id: contact?.person?.salutation_id,
            first_name: contact?.person?.first_name,
            last_name: contact?.person?.last_name,
            line_item: contact?.person?.line_item,
            title: contact?.person?.title,
            disable_currency:
                contact.invoices.length ||
                contact.bills.length ||
                contact.estimates.length,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onSubmit = handleSubmit(async (values) => {
    try {
        let contact
        if (values.contact_type === CONTACT_RELATIONSHIP_TYPES.person) {
            contact = await executeSavePerson(values, route.params.id)
        } else {
            contact = await executeSaveOrg(values, route.params.id)
        }

        await router.push({
            name: 'contacts.detail',
            params: { id: contact.id },
        })

        // Show a notification on success
        successNotify({
            title: t('general.success'),
            text: t('contacts.edit.editSuccess'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
})

const onCancel = () => {
    router.push({
        name: 'contacts',
    })
}

const { defaultCurrency } = useCurrencies()
const { value: currency } = useField('currency_id')

watch(
    () => defaultCurrency.value,
    (value) => {
        if (!value) {
            return
        }

        if (!currency.value) {
            setValues({
                currency_id: value.value,
            })
        }
    },
    { immediate: true }
)
</script>
