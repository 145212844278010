<template>
    <base-button-group>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': !filterValues.type,
            }"
            @click="viewChange()"
        >
            {{ $t('contacts.list.viewAll') }}
        </base-button>
        <base-button
            v-for="contactType in contactTypes"
            variant="default"
            :key="contactType.value"
            :class="{
                'bg-gray-100': filterValues.type === contactType.value,
            }"
            @click="viewChange(contactType.value)"
        >
            {{ contactType.label }}
        </base-button>
    </base-button-group>
</template>

<script setup>
import { useContactTypes } from '@tenant/composables'
import { useFilters } from '@tenant/composables'

const { contactTypes } = useContactTypes()

const queries = {
    type: 'filter.contact_type_id',
}
const { filterValues, updateFilter } = useFilters(queries)

const viewChange = (contactType = null) =>
    updateFilter(queries.type, contactType)
</script>
