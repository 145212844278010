<template>
    <base-modal close size="xl" @modal-close="closeModal" :show="show">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{
                    $t('contacts.feedHistory.modal.header', {
                        causer: causerName,
                    })
                }}
            </span>
        </template>

        <p class="pb-5 text-sm text-gray-500">
            {{ $t('contacts.feedHistory.modal.description') }}
        </p>

        <div>
            <table class="w-full rounded-lg border border-gray-200">
                <thead>
                    <tr>
                        <th
                            class="pointer-events-none bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    $t('contacts.feedHistory.modal.table.field')
                                }}
                            </div>
                        </th>
                        <th
                            class="pointer-events-none bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    $t(
                                        'contacts.feedHistory.modal.table.old_value'
                                    )
                                }}
                            </div>
                        </th>
                        <th
                            class="pointer-events-none bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    $t(
                                        'contacts.feedHistory.modal.table.new_value'
                                    )
                                }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="cursor-pointer border border-gray-200 bg-white">
                    <!-- Table rows go here -->
                    <tr
                        v-for="(value, key) in newAttributes"
                        :key="key"
                        class="px-6 py-4 text-left text-sm font-normal text-gray-500 hover:bg-gray-100"
                    >
                        <td
                            class="border px-6 py-4 text-left text-sm font-normal text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    $t(
                                        `contacts.feedHistory.fieldsMapping.${subjectModelName}.${key}`
                                    )
                                }}
                            </div>
                        </td>
                        <td
                            class="border px-6 py-4 text-left text-sm font-normal text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    isDateTimeString(oldAttributes[key])
                                        ? humanTimeFormat(oldAttributes[key])
                                        : oldAttributes[key]
                                }}
                            </div>
                        </td>
                        <td
                            class="border px-6 py-4 text-left text-sm font-normal text-gray-500"
                        >
                            <div class="flex items-center gap-1">
                                {{
                                    isDateTimeString(value)
                                        ? humanTimeFormat(value)
                                        : value
                                }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button @click="closeModal">
                    {{ $t('contacts.feedHistory.modal.buttons.close') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { humanTimeFormat } from '@tenant/utils/day'

const emit = defineEmits(['modal-close'])

defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    causerName: {
        type: String,
        required: true,
        default: '',
    },
    oldAttributes: {
        type: Object,
        required: true,
        default: () => ({}),
    },
    newAttributes: {
        type: Object,
        required: true,
        default: () => ({}),
    },
    subjectModelName: {
        type: String,
        required: true,
        default: '',
    },
})

const closeModal = () => {
    emit('modal-close')
}

const isDateTimeString = (str) => {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/
    return dateTimeRegex.test(str) && !isNaN(Date.parse(str))
}
</script>
