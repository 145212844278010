<template>
    <div>
        <div
            class="mb-2.5 flex items-start justify-between border-b border-gray-200 pb-5"
        >
            <div class="flex flex-1 flex-col gap-1 pr-2">
                <h5 class="text-lg font-medium text-gray-900">
                    {{ heading }}
                </h5>
                <p class="text-sm font-normal text-gray-500">
                    {{ description }}
                </p>
            </div>
            <div class="flex">
                <form-single-select
                    v-model="date"
                    :options="dateOptions"
                    size="sm"
                    :show-clear="false"
                />
            </div>
        </div>

        <div class="relative flex h-[200px]">
            <stackbar-chart
                class="mb-2"
                :height="200"
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
            <div
                v-if="loading"
                class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60"
            >
                <base-loading />
            </div>
        </div>
        <p class="mb-2.5 block text-center text-xs font-medium text-gray-500">
            {{ $t('general.month') }}
        </p>
        <p class="text-sm font-normal text-gray-700">
            <slot name="bottom" v-bind="{ date }" />
        </p>
    </div>
</template>

<script setup>
import { OPTIONS } from '@tenant/modules/tenant/contacts/utils/constants.utils'
import { getShortNumber } from '@tenant/utils/helper'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    heading: {
        type: String,
    },
    description: {
        type: String,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
    },
    labels: {
        type: Array,
    },
    income: {
        type: Array,
    },
    expenses: {
        type: Array,
    },
    modelValue: {
        type: String,
        default: '',
    },
})

const { t } = useI18n()

const date = computed({
    get() {
        return props.modelValue || OPTIONS.LAST_6_MONTHS
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const dateOptions = [
    {
        label: t(
            `contacts.detail.overview.dateOptions.${OPTIONS.LAST_6_MONTHS}`
        ),
        value: OPTIONS.LAST_6_MONTHS,
    },
    {
        label: t(
            `contacts.detail.overview.dateOptions.${OPTIONS.LAST_12_MONTHS}`
        ),
        value: OPTIONS.LAST_12_MONTHS,
    },
]

const chartOptions = computed(() => ({
    plugins: {
        legend: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            ticks: {
                color: '#667085',
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            border: {
                display: false,
            },
            beginAtZero: true,
            max: ({ chart }) => {
                let max = 5

                chart.data.datasets.forEach((dataset) => {
                    const datasetMax = Math.max(...dataset.data)
                    if (datasetMax === 5) {
                        max = 6
                    } else {
                        max = datasetMax > max ? datasetMax : max
                    }
                })

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                color: '#667085',
                count: 6,
                padding: 8,
                callback: function (val) {
                    return getShortNumber(val)
                },
            },
            grid: {
                drawBorder: false,
                color: '#F2F4F7',
            },
        },
    },
}))

const chartData = computed(() => ({
    labels: props.labels,
    datasets: [
        {
            label: t('dashboard.incomeExpenses.income'),
            backgroundColor: props.color,
            data: props.income,
        },
        {
            label: t('dashboard.incomeExpenses.expenses'),
            backgroundColor: '#98A2B3',
            data: props.expenses,
        },
    ],
}))
</script>
