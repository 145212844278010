<template>
    <base-section :hide-info-block="true">
        <template #right>
            <div
                class="flex gap-0.5 rounded-lg border border-gray-100 bg-gray-50 p-1"
            >
                <base-button
                    variant="link-gray"
                    @click="selectedTab = 'address'"
                    :class="{
                        'bg-white shadow-sm': selectedTab === 'address',
                    }"
                >
                    {{ $t('contacts.form.addressSection.title') }}
                </base-button>
                <base-button
                    variant="link-gray"
                    @click="selectedTab = 'contact'"
                    :class="{
                        'bg-white shadow-sm': selectedTab === 'contact',
                    }"
                >
                    {{ $t('contacts.form.contactSection.title') }}
                </base-button>
                <base-button
                    variant="link-gray"
                    @click="selectedTab = 'payment'"
                    :class="{
                        'bg-white shadow-sm': selectedTab === 'payment',
                    }"
                >
                    {{ $t('contacts.form.paymentSection.title') }}
                </base-button>
                <base-button
                    variant="link-gray"
                    @click="selectedTab = 'additional'"
                    :class="{
                        'bg-white shadow-sm': selectedTab === 'additional',
                    }"
                >
                    {{ $t('contacts.form.additionalSection.title') }}
                </base-button>
                <base-button
                    variant="link-gray"
                    @click="selectedTab = 'other'"
                    :class="{
                        'bg-white shadow-sm': selectedTab === 'other',
                    }"
                >
                    {{ $t('contacts.form.defaultSettings.title') }}
                </base-button>
            </div>

            <contacts-section-address
                v-show="selectedTab === 'address'"
                hide-info-block
                vertical
            />
            <contacts-section-contact
                v-show="selectedTab === 'contact'"
                hide-info-block
                vertical
            />
            <contacts-section-payment
                v-show="selectedTab === 'payment'"
                hide-info-block
                vertical
            />
            <contacts-section-additional
                v-show="selectedTab === 'additional'"
                hide-info-block
                vertical
            />

            <contacts-section-other-details
                v-show="selectedTab === 'other'"
                hide-info-block
                vertical
            />
        </template>
    </base-section>
</template>

<script setup>
const selectedTab = ref('address')
</script>
