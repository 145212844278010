<template>
    <base-card class="relative min-w-[300px]">
        <p class="mb-6 text-gray-900">
            {{
                type === 'receivable'
                    ? $t('contacts.detail.overview.amountCard.receivables')
                    : $t('contacts.detail.overview.amountCard.payables')
            }}
        </p>

        <base-context-menu
            size="md"
            dropdown-classes="z-50"
            class="!absolute right-5 top-5"
        >
            <template v-if="type === 'receivable'">
                <base-context-menu-item
                    @click="$router.push({ name: 'invoices.create' })"
                >
                    {{
                        $t('contacts.detail.overview.amountCard.createInvoice')
                    }}
                </base-context-menu-item>
                <base-context-menu-item
                    @click="$router.push({ name: 'estimates.create' })"
                >
                    {{
                        $t('contacts.detail.overview.amountCard.createEstimate')
                    }}
                </base-context-menu-item>
            </template>

            <template v-else>
                <base-context-menu-item
                    @click="$router.push({ name: 'purchases.bill.create' })"
                >
                    {{ $t('contacts.detail.overview.amountCard.createBill') }}
                </base-context-menu-item>
                <base-context-menu-item
                    @click="$router.push({ name: 'purchases.expense.create' })"
                >
                    {{
                        $t('contacts.detail.overview.amountCard.createExpense')
                    }}
                </base-context-menu-item>
            </template>
        </base-context-menu>

        <h4 class="font-semibold text-gray-900">
            {{ $filters.symbolCurrency(props.amount, props.currency) }}
        </h4>
    </base-card>
</template>

<script setup>
const props = defineProps({
    amount: {
        type: [String, Number],
        required: true,
    },
    currency: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
})
</script>
