<template>
    <div class="flex w-full items-center gap-2">
        <span
            v-if="showIcon"
            class="relative flex h-10 w-10 items-center justify-center rounded-full bg-primary-500 text-md text-white focus:ring-primary-100"
        >
            <base-icon size="md" :name="icon" variant="inherit" />
        </span>
        <div class="whitespace-nowrap text-sm font-medium text-gray-900">
            <slot name="title">{{ title }}</slot>
        </div>
        <div class="whitespace-nowrap text-sm font-normal text-gray-500">
            <slot name="description">{{ description }}</slot>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    showIcon: {
        type: Boolean,
        default: true,
    },
})

const icon = computed(() => {
    const { item } = props

    if (item.organisation_id) {
        return 'line-icons:general:building-03'
    }

    return 'line-icons:users:user-03'
})

const title = computed(() => {
    const { item } = props

    if (item.organisation_id) {
        return item.organisation?.name
    }

    return `${item.person?.first_name} ${item.person?.last_name}`
})

const description = computed(() => {
    const { item } = props

    return item.email && item.email[0] && item.email[0].email
})
</script>
