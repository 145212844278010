<template>
    <base-section :title="$t('contacts.form.paymentSection.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('contacts.form.paymentSection.description01') }}
            </span>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('contacts.form.paymentSection.description02') }}
            </span>
        </template>

        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <form-text-input
                        v-model="iban"
                        label="contacts.form.paymentSection.iban"
                        :error-message="errorIban"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-model="taxReference"
                        label="contacts.form.paymentSection.taxReference"
                        :error-message="errorTaxReference"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="vatId"
                        label="contacts.form.paymentSection.vatId"
                        :error-message="errorVatId"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-model="swiftCode"
                        label="contacts.form.paymentSection.swiftCode"
                        :error-message="errorSwiftCode"
                        :disabled="disabled"
                    />
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: iban, errorMessage: errorIban } = useField('iban')
const { value: taxReference, errorMessage: errorTaxReference } =
    useField('tax_reference')
const { value: vatId, errorMessage: errorVatId } = useField('vat_id')
const { value: swiftCode, errorMessage: errorSwiftCode } =
    useField('swift_code')
</script>
