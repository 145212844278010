<template>
    <base-tabs
        variant="horizontal"
        :items="tabs"
        tab-style="underlined"
        :has-margin="false"
        tab-class="px-6 mb-6 border-b border-gray-200"
    >
        <template #content>
            <router-view
                :contact="contact"
                @update-badges="setBadges"
            ></router-view>
        </template>
    </base-tabs>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import emitter from '@tenant/core/init/emitter'
const { t } = useI18n()

const props = defineProps({
    contact: {
        type: [Object, null],
        required: true,
    },
})

const tabs = ref([
    {
        id: 'overview',
        label: t('contacts.tabs.overview'),
        route: 'contacts.detail.overview',
    },
    {
        id: 'feed-history',
        label: t('contacts.tabs.feedHistory'),
        route: 'contacts.detail.feed-history',
    },
    {
        id: 'statement',
        label: t('contacts.tabs.statement'),
        route: 'contacts.detail.statement',
    },
    {
        id: 'mails',
        label: t('contacts.tabs.mails'),
        route: 'contacts.detail.mails',
    },
])

const { execute } = useApi(`/api/contacts/{id}/badges`, 'GET')
onMounted(() => {
    setBadges()
})

const setBadges = async () => {
    const response = await execute({
        params: { id: props.contact.id },
    })

    for (const [key, value] of Object.entries(response)) {
        const tab = tabs.value.find((tab) => tab.id === key)
        if (tab) {
            tab.badgeContent = value
        }
    }
}

emitter.on('update-badges', () => {
    setBadges()
})

watch(
    () => props.contact.id,
    () => {
        setBadges()
    }
)
</script>
