<template>
    <invoice-data-grid
        :params="params"
        :grid-columns="columns"
        :replace-query="false"
        @invoice-total="onInvoiceTotalReceived"
        class="rounded-none"
    >
        <template #empty>
            <div class="flex gap-0.5">
                <p class="text-xs text-gray-700">
                    {{
                        $t('contacts.transactions.noResults', {
                            type: $t('contacts.transactions.invoices'),
                        })
                    }}
                </p>
                <p class="text-xs text-gray-700">-</p>
                <p
                    v-if="$acl.can('create_invoices')"
                    class="cursor-pointer text-xs text-primary-700"
                    @click="redirectToCreate"
                >
                    {{ $t('contacts.transactions.addNew') }}
                </p>
            </div>
        </template>

        <template #empty-actions>
            <base-button
                v-if="$acl.can('create_invoices')"
                size="md"
                variant="primary"
                icon="line-icons:general:plus"
                @click="redirectToCreate"
            >
                {{ $t('invoices.list.add') }}
            </base-button>
        </template>
    </invoice-data-grid>
</template>

<script setup>
const route = useRoute()
const router = useRouter()

const { t } = useI18n()
const columns = [
    {
        property: 'date',
        label: t('invoices.list.date'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'number',
        label: t('invoices.list.number'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'amountGross',
        label: t('invoices.list.amountGross'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'unpaidGross',
        label: t('invoices.list.unpaidGross'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'status',
        label: t('invoices.list.status'),
    },
]

const params = ref({
    filter: {
        'saleDocument.contact.contact_id': route.params.id,
    },
})

const redirectToCreate = () => {
    router.push({
        name: 'invoices.create',
        query: { contact_id: route.params.id },
    })
}

const invoiceTotal = ref(0)
const onInvoiceTotalReceived = (total) => {
    invoiceTotal.value = total
}
</script>
