<template>
    <form-single-select
        label="contacts.form.generalSection.contactTypes.type"
        :model-value="relationshipType"
        :options="relationshipTypeOptions"
    />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

const props = defineProps({
    personFilter: {
        type: String,
    },
    organisationFilter: {
        type: String,
    },
})

const { t } = useI18n()
const relationshipTypeOptions = [
    {
        label: t('contacts.relationship.personLabel'),
        value: CONTACT_RELATIONSHIP_TYPES.person,
    },
    {
        label: t('contacts.relationship.organisationLabel'),
        value: CONTACT_RELATIONSHIP_TYPES.organisation,
    },
]

const relationshipType = computed(() => {
    const { personFilter, organisationFilter } = props

    if (typeof personFilter !== 'undefined') {
        return CONTACT_RELATIONSHIP_TYPES.person
    }

    if (typeof organisationFilter !== 'undefined') {
        return CONTACT_RELATIONSHIP_TYPES.organisation
    }

    return null
})
</script>
