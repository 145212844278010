<template>
    <base-modal close @modal-close="closeModal" :show="show" size="lg">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('contacts.cannotDelete.title') }}
            </span>
        </template>

        <p class="pb-2.5 text-sm font-medium text-gray-700">
            {{ $t('contacts.cannotDelete.description') }}
        </p>

        <p class="text-sm text-gray-700">
            {{
                $t('contacts.cannotDelete.linkedDocuments', { msg: linkedDocs })
            }}
        </p>

        <template #modal-footer>
            <div class="flex justify-end">
                <base-button @click="closeModal">
                    {{ $t('general.ok') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'modal-confirm'])
const { t } = useI18n()

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
        default: '',
    },
    contact: {
        type: [Object, null],
        default: () => ({}),
    },
})

const linkedDocs = computed(() => {
    return props.contact.invoices
        .reduce((acc, { invoice }, index) => {
            if (index > 0) acc += ', '
            if (index > 2) {
                acc += t('contacts.cannotDelete.andMoreText')
                return acc
            }
            acc += `${t('contacts.cannotDelete.invoiceNo', {
                number: invoice.sale_document.number,
            })}, ${invoice.sale_document.number}.pdf`
            return acc
        }, '')
        .concat('.')
})

const closeModal = () => {
    emit('modal-close')
}
</script>
