import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'contacts',
        path: '/contacts',
        redirect: {
            name: 'contacts.list',
        },
        children: [
            {
                name: 'contacts.list',
                path: '',
                component: () => import('./pages/contacts-list.vue'),
            },
            {
                name: 'contacts.detail',
                path: 'detail/:id',
                component: () => import('./pages/contacts-detail.vue'),
                redirect: {
                    name: 'contacts.detail.overview',
                },
                children: [
                    {
                        name: 'contacts.detail.overview',
                        path: 'overview',
                        component: () =>
                            import('./views/contacts-detail-overview.vue'),
                    },
                    {
                        name: 'contacts.detail.feed-history',
                        path: 'feed-history',
                        component: () =>
                            import('./views/contacts-detail-feed-history.vue'),
                    },
                    {
                        name: 'contacts.detail.statement',
                        path: 'statement',
                        component: () =>
                            import('./views/contacts-detail-statement.vue'),
                    },
                    {
                        name: 'contacts.detail.mails',
                        path: 'mails',
                        component: () =>
                            import('./views/contacts-detail-mails.vue'),
                    },
                ],
            },
            {
                name: 'contacts.add',
                path: 'add',
                component: () => import('./pages/contacts-add.vue'),
                meta: {
                    permission: 'create_contacts',
                },
            },
            {
                name: 'contacts.edit',
                path: ':id',
                component: () => import('./pages/contacts-edit.vue'),
                meta: {
                    permission: 'update_contacts',
                },
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_contacts',
    },
}
