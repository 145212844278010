<template>
    <base-modal close @modal-close="closeModal" :show="show" size="lg">
        <template #modal-header>
            <h5 class="text-lg font-medium">
                {{ title }}
            </h5>
        </template>

        <form-control-group>
            <!-- Receiver -->
            <form-tags-input
                v-model="receiver"
                :label="
                    $t('contacts.detail.statement.sendEmail.receiver.label')
                "
                :placeholder="
                    $t(
                        'contacts.detail.statement.sendEmail.receiver.placeholder'
                    )
                "
                :error-message="errorReceiver"
            />

            <!-- Subject -->
            <form-text-input
                v-model="subject"
                :label="$t('contacts.detail.statement.sendEmail.subject.label')"
                :placeholder="
                    $t(
                        'contacts.detail.statement.sendEmail.subject.placeholder'
                    )
                "
                :error-message="errorSubject"
            />

            <!-- Message -->
            <form-rich-text-editor
                v-model="message"
                :label="$t('contacts.detail.statement.sendEmail.message.label')"
                :error-message="errorMessage"
            />
        </form-control-group>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button full-width :loading="loading" @click="onConfirm">
                    {{ $t('general.send') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import dayjs from 'dayjs'
import { DATE_HUMAN_FORMAT_NO_COMMA } from '@tenant/utils/constants'

const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },

    loading: {
        type: Boolean,
        default: false,
    },

    title: {
        type: String,
        default: '',
    },

    startDate: {
        type: Date,
        default: null,
    },

    endDate: {
        type: Date,
        default: null,
    },

    contact: {
        type: Object,
        default: () => ({}),
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = () => {
    emit('modal-confirm')
}

const formatDate = (date) => dayjs(date).format(DATE_HUMAN_FORMAT_NO_COMMA)

const { t } = useI18n()
const { value: receiver, errorMessage: errorReceiver } = useField('receiver')
const { value: subject, errorMessage: errorSubject } = useField('subject')
const { value: message, errorMessage } = useField('message')

watch(
    () => props.show,
    (value) => {
        if (value) {
            const defaultEmail = props.contact?.email?.[0]
            receiver.value = defaultEmail ? [defaultEmail.email] : []
            subject.value = props.subjectText
            message.value = t(
                'contacts.detail.statement.sendEmail.message.value',
                {
                    startDate: formatDate(props.startDate),
                    endDate: formatDate(props.endDate),
                }
            )
            subject.value = t(
                'contacts.detail.statement.sendEmail.subject.value',
                {
                    startDate: formatDate(props.startDate),
                    endDate: formatDate(props.endDate),
                }
            )
        }
    }
)
</script>
