export default {
    contacts: {
        title: 'Contacts',
        description:
            'A contact can be a customer, but also something else like a supplier',
        emptyText: 'No contacts found',
        emptySubText:
            'Currently there are no contacts, feel free to create a contact of an organisation.',
        addPerson: 'Add Person',
        selectPerson: 'Select existing person',
        createNewPerson: 'Create new person',
        addOrganisation: 'Add Organisation',
        addContact: 'Add contact',
        editPerson: 'Edit',

        legalName: 'Legal name',
        customerNumber: 'Customers-Number *',
        lineItem: 'Line item',
        creditorNumber: 'Debtor/Creditor number',

        relationship: {
            personLabel: 'Person',
            personDescription: 'Person directly connected to the business',
            organisationLabel: 'Organisation',
            organisationDescription: 'Organisation is a legal entity',
        },

        tabs: {
            list: 'People',
            overview: 'Overview',
            feedHistory: 'Feed history',
            transactions: 'Transactions',
            orders: 'Orders',
            invoices: 'Invoices',
            bills: 'Bills',
            statement: 'Statement',
            mails: 'Mails',
        },

        feedHistory: {
            modal: {
                header: 'Changes made by {causer}',
                description:
                    'Within the Table you see the changes that has been made.',
                buttons: {
                    close: 'I understand, close the modal',
                },
                table: {
                    field: 'Field',
                    old_value: 'Old value',
                    new_value: 'New value',
                },
            },
            time_ago: {
                just_now: 'Just now',
                seconds_ago: '1 second ago | {count} seconds ago',
                minutes_ago: '1 minute ago | {count} minutes ago',
                hours_ago: '1 hour ago | {count} hours ago',
                days_ago: '1 day ago | {count} days ago',
            },
            btn_view_changes: 'View Details',
            btn_hide_changes: 'Hide changes',
            fieldsMapping: {
                Contact: {
                    id: 'Identifier',
                    people_id: 'People Identifier',
                    organisation_id: 'Organization Identifier',
                    contact_type_id: 'Contact Type Identifier',
                    customer_number: 'Customer Number',
                    legal_name: 'Legal Name',
                    debtor_creditor_number: 'Debtor/Creditor Number',
                    phone: 'Phone',
                    email: 'Email',
                    web: 'Web',
                    iban: 'IBAN',
                    tax_reference: 'Tax Reference',
                    vat_id: 'VAT ID',
                    tax_rule_id: 'Tax Rule Identifier',
                    payment_term: 'Payment Term',
                    currency_id: 'Currency Identifier',
                    swift_code: 'SWIFT Code',
                    description: 'Description',
                    created_at: 'Created At',
                    updated_at: 'Updated At',
                    organisation: {
                        name: 'Organisation Name',
                    },
                    currency: {
                        name: 'Currency Name',
                    },
                    tax_rule: {
                        name: 'Tax Rule Name',
                    },
                    contact_addresses: 'Contact Addresses',
                    tags: 'Tags',
                    contactType: {
                        name: 'Contact Type',
                    },
                },
                Organisation: {
                    name: 'Organisation name',
                },
                Person: {
                    title: 'Title',
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    line_item: 'Line Item',
                    birthday: 'Birthday',
                    salutation: {
                        name: 'Salutation',
                    },
                },
                Bill: {
                    status: 'Status',
                    type: 'Type',
                    tax_type: 'Tax Type',
                    document_number: 'Document Number',
                    supplier_name: 'Supplier Name',
                    date_of_document: 'Date of Document',
                    due_date: 'Due Date',
                    date_of_delivery: 'Date of Delivery',
                    amount_net_total: 'Amount Net Total',
                    amount_gross_total: 'Amount Gross Total',
                    unpaid_amount: 'Unpaid Amount',
                    flag: 'Flag',
                    costCentre: {
                        name: 'Cost Centre Name',
                    },
                    currency: {
                        name: 'Currency Name',
                    },
                    taxRule: {
                        name: 'Tax Rule Name',
                    },
                },
                Invoice: {
                    country_id: 'Country Identifier',
                    currency_id: 'Currency Identifier',
                    user_id: 'User Identifier',
                    tax_rule_id: 'Tax Rule Identifier',
                    is_approved: 'Is Approved',
                    invoice_header: 'Invoice Header',
                    invoice_number: 'Invoice Number',
                    sent_at: 'Sent At',
                    date_of_invoice: 'Date of Invoice',
                    due_date: 'Due Date',
                    date_of_delivery: 'Date of Delivery',
                    period_of_service_from: 'Period of Service From',
                    period_of_service_to: 'Period of Service To',
                    reference: 'Reference',
                    status: 'Status',
                    header_content: 'Header Content',
                    footer_content: 'Footer Content',
                    cash_discount_day: 'Cash Discount Day',
                    cash_discount_percent: 'Cash Discount Percent',
                    amount_net_total: 'Amount Net Total',
                    amount_gross_total: 'Amount Gross Total',
                    unpaid_amount: 'Unpaid Amount',
                    address: 'Address',
                    taxRule: {
                        name: 'Tax Rule Name',
                    },
                    country: {
                        name: 'Country Name',
                    },
                    currency: {
                        name: 'Currency Name',
                    },
                    internalContact: {
                        name: 'Internal Contact Name',
                    },
                },
                SaleDocument: {
                    country_id: 'Country Identifier',
                    currency_id: 'Currency Identifier',
                    user_id: 'User Identifier',
                    tax_rule_id: 'Tax Rule Identifier',
                    is_approved: 'Is Approved',
                    invoice_header: 'Invoice Header',
                    invoice_number: 'Invoice Number',
                    sent_at: 'Sent At',
                    date_of_invoice: 'Date of Invoice',
                    due_date: 'Due Date',
                    date_of_delivery: 'Date of Delivery',
                    period_of_service_from: 'Period of Service From',
                    period_of_service_to: 'Period of Service To',
                    reference: 'Reference',
                    status: 'Status',
                    header_content: 'Header Content',
                    footer_content: 'Footer Content',
                    cash_discount_day: 'Cash Discount Day',
                    cash_discount_percent: 'Cash Discount Percent',
                    amount_net_total: 'Amount Net Total',
                    amount_gross_total: 'Amount Gross Total',
                    unpaid_amount: 'Unpaid Amount',
                    address: 'Address',
                    taxRule: {
                        name: 'Tax Rule Name',
                    },
                    country: {
                        name: 'Country Name',
                    },
                    currency: {
                        name: 'Currency Name',
                    },
                    internalContact: {
                        name: 'Internal Contact Name',
                    },
                },
            },
            noticeTextareaPlaceholder: 'Write your notes here...',
            noticeNotify: 'Notify employees via e-mail',
            noticeDate: 'Date of notice',
            saveNotice: 'Save notice',
            organisation: {
                created: 'has created a new organisation for {contact}',
                updated: 'has updated the organisation for {contact}',
                removed: 'has removed the organisation for {contact}',
            },
            contact: {
                created: 'has added {contact} as a new contact',
                updated: 'has updated the contact {contact}',
                removed: 'has removed the contact {contact}',
                sent: 'has sent the statement report to {contact}',
            },
            person: {
                updated: 'has updated the contact {contact}',
                removed: 'has removed the contact {contact}',
            },
            invoice: {
                added: 'has created a new invoice {url}',
                changed: 'has updated the invoice {url} for {contact}',
                removed: 'has removed the invoice {url} for {contact}',
                paid: 'has booked an amount of {amount}{currency} for invoice {url} and marked it as {status} for {contact}',
                generatePdf:
                    'has generated the {pdf} for invoice {url} for {contact}',
                cancel: 'has canceled the invoice {url} for {contact}',
                approve: 'has approved the invoice {url} for {contact}',
                sent: 'has sent the invoice {url} for {contact}',
                markAsSent:
                    'has marked the invoice {url} as sent for {contact}',
            },
            estimate: {
                added: 'has created a new estimate {url}',
                changed: 'has updated the estimate {url} for {contact}',
                removed: 'has removed the estimate {url} for {contact}',
                generatePdf:
                    'has generated the {pdf} for estimate {url} for {contact}',
                cancel: 'has declined the estimate {url} for {contact}',
                approve: 'has approved the estimate {url} for {contact}',
                sent: 'has sent the estimate {url} for {contact}',
                markAsSent:
                    'has marked the estimate {url} as sent for {contact}',
                invoiced:
                    'has converted an estimate {url} to invoice for {contact}',
            },
            sale_document: {
                added: 'has created a new invoice {url}',
                changed: 'has updated the invoice {url} for {contact}',
                removed: 'has removed the invoice {url} for {contact}',
                paid: 'has booked an amount of {amount}{currency} for invoice {url} and marked it as {status} for {contact}',
                generatePdf:
                    'has generated the {pdf} for invoice {url} for {contact}',
                cancel: 'has canceled the invoice {url} for {contact}',
                approve: 'has approved the invoice {url} for {contact}',
                sent: 'has sent the invoice {url} for {contact}',
                markAsSent:
                    'has marked the invoice {url} as sent for {contact}',
            },
            bill: {
                added: 'has added a new bill {url} for {contact}',
                changed: 'has updated the bill {url} for {contact}',
                removed: 'has removed the bill {url} for {contact}',
                paid: 'has booked an amount of {amount}{currency} for bill {url} and marked it as {status} for {contact}',
            },
            expense: {
                added: 'has added a new expense {url} for {contact}',
                changed: 'has updated the expense {url} for {contact}',
                removed: 'has removed the expense {url} for {contact}',
                paid: 'has booked an amount of {amount}{currency} for expense {url} and marked it as {status} for {contact}',
            },
            document: {
                created: 'has created a new folder {url} for {contact}',
                updated: 'has updated the bill {url} for {contact}',
                deleted: 'has removed the bill {url} for {contact}',
            },
            sale_document_contact: {
                assigned: 'has assigned {type} {url} for {contact}',
                unassigned: 'has unassigned {type} {url} for {contact}',
                changed: 'has changed the {type} {url} for {contact}',
            },
            contact_address: {
                created: 'has added a new address for {contact}',
                updated: 'has updated the address for {contact}',
                deleted: 'has deleted the address for {contact}',
            },
        },

        transactions: {
            noResults: 'There are no {type}',
            addNew: 'Add new',
            invoices: 'Invoices',
            expenses: 'Expenses',
            bills: 'Bills',
            creditNotes: 'Credit Notes',
        },

        sidebar: {
            label: 'Contacts',
        },

        list: {
            customerNo: 'Customer No.',
            contactPerson: 'Contact Person & Email',
            phoneNumber: 'Phone',
            type: 'Type',
            balance: 'Balance / Overdue',
            receivables: 'Receivables',
            payables: 'Payables',
            viewAll: 'View All',
            suppliers: 'Suppliers',
            customers: 'Customers',
            deleteSuccess: 'Contact successfully deleted!',
            deleteConfirmation: 'Delete this contact?',

            contextMenu: {
                addInvoice: 'Add invoice',
                addRecurringInvoice: 'Add recurring invoice',
                newProposal: 'New proposal',
                newOrderConfirmation: 'New order confirmation',
                newDeliveryNote: 'New delivery note',
                createLetter: 'Create letter',
                pdfDownload: 'PDF download/print',
            },
        },

        edit: {
            title: 'Edit contact',
            description:
                'A contact can be a customer, but also something else like a supplier',
            editSuccess: 'Contact successfully updated!',
        },

        detail: {
            title: 'Contact detail',
            description: `A contact can be a customer, but also something else like {type}`,
            personText: 'a person',
            organisationText: 'an organisation',
            allContacts: 'All contacts',

            overview: {
                thereIsNoPrimaryContact:
                    'There is no primary contact information',
                dateOptions: {
                    last_6_months: 'Last 6 months',
                    last_12_months: 'Last 12 months',
                },
                chart: {
                    heading: 'Income & Expenses',
                    description:
                        "This chart is displayed in the organization's base currency.",
                },
                income: {
                    heading: 'Income',
                    description:
                        "This chart is displayed in the organization's base currency.",
                    total: 'Total Income ( {date} )',
                },
                expense: {
                    heading: 'Expenses',
                    description:
                        "This chart is displayed in the organization's base currency.",
                    total: 'Total Expenses ( {date} )',
                },
                amountCard: {
                    currency: 'Currency',
                    receivables: 'Outstanding receivables',
                    payables: 'Outstanding payables',
                    viewDetail: 'View detail',
                    createInvoice: 'Create invoice',
                    createExpense: 'Create expense',
                    createBill: 'Create bill',
                    createEstimate: 'Create estimate',
                },
            },

            invoices: {
                table: {
                    headers: {
                        status: 'Status',
                        dueDate: 'Due date',
                        number: 'No.',
                        customer: 'Customer',
                        date: 'Date',
                        amount: 'Amount (Net)',
                    },

                    since: 'Since',
                    days: 'days',
                    invoiceNumber: 'Invoice no.',
                },
            },

            people: {
                messages: {
                    success: 'Person has been removed successfully',
                },
            },

            statement: {
                headline: 'Customer Statement for {name}',
                dates: 'From {startDate} To {endDate}',
                printTooltip: 'Print',
                PDFTooltip: 'PDF',
                sendMailButton: 'Send Email',

                sendEmail: {
                    title: 'Send Email Statement for {name}',
                    receiver: {
                        label: 'Receiver *',
                        placeholder: 'Receiver',
                    },
                    subject: {
                        label: 'Subject *',
                        placeholder:
                            'Statement from DD MMM YYYY to DD MMM YYYY',
                        value: 'Account Statement from {startDate} to {endDate}',
                    },
                    message: {
                        label: 'Message',
                        value: 'Attached with this email is a list of all transactions for the period between <b>{startDate}</b> to <b>{endDate}</b>. If you have any questions, just drop us an email or call us.',
                    },
                    successMessage: 'Statement has been sent successfully',
                },

                file: {
                    summaryInfo: {
                        title: 'Statement of Accounts',
                        to: 'To',
                        accountSummaryTitle: 'Account Summary',
                        openingBalance: 'Opening Balance',
                        invoicedAmount: 'Invoiced Amount',
                        amountReceived: 'Amount Received',
                        balanceDue: 'Balance Due',
                    },
                    lineItems: {
                        date: 'Date',
                        transactions: 'Transactions',
                        details: 'Details',
                        amount: 'Amount',
                        payments: 'Payments',
                        balance: 'Balance',
                        balanceDue: 'Balance Due',
                    },
                    footer: {
                        tel: 'Tel.: {value}',
                        email: 'E-Mail: {value}',
                        web: 'Web: {value}',
                        registrationNumber: 'HR-Nr.: {value}',
                        vatId: 'VAT.-ID: {value}',
                        director: 'Director: {value}',
                        bank: 'Bank: {value}',
                        iban: 'IBAN: {value}',
                        accountNumber: 'Account no.: {value}',
                    },
                },
            },

            mails: {
                systemMails: 'System Mails',
                date: 'Date',
                to: 'To: {email}',
                emptyText: '',
                emptySubText: '',
                actions: {
                    contact_invoice_mail: 'Invoice notification',
                    contact_statement_mail: 'Statement report',
                },
                subject: '{subject} from {sender}',
            },
        },

        add: {
            title: 'Create contact',
            description:
                'A contact can be a customer, but also something else like a supplier',
            addSuccess: 'Contact successfully created!',
        },

        form: {
            generalSection: {
                title: 'General information',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

                organisationName: 'Organisation name *',
                firstName: 'First name *',
                lastName: 'Last name *',

                contactTypes: {
                    type: 'Type',
                    supplier: 'Supplier',
                    customer: 'Customer',
                    partner: 'Partner',
                    prospect: 'Prospect',
                },
            },
            addressSection: {
                title: 'Address',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                addAddress: 'Add address',

                address: {
                    street: 'Street',
                    postcode: 'Postcode',
                    city: 'City',
                    country: 'Country',
                },

                contactAddressTypes: {
                    work: 'Work',
                    private: 'Private',
                    invoice: 'Invoice',
                    delivery: 'Delivery',
                    collection: 'Collection',
                },
            },
            contactSection: {
                title: 'Contact detail',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                addPhone: 'Add phone',
                addEmail: 'Add email',
                addWeb: 'Add web',

                contactDetailTypes: {
                    work: 'Work',
                    autobox: 'Autobox',
                    fax: 'Fax',
                    mobile: 'Mobile',
                    newsletter: 'Newsletter',
                    private: 'Private',
                    invoice: 'Invoice',
                },
            },
            paymentSection: {
                title: 'Payment information',
                description01:
                    'Enter your bank information so that your bills can be paid.',
                description02:
                    'You want your PayPal address, directly on your invoice? Then add it here.',

                iban: 'IBAN',
                taxReference: 'T.I.C. (Tax Identification Code)',
                vatId: 'VAT ID',
                swiftCode: 'Swift code',
            },
            conditionsSection: {
                title: 'Conditions',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

                cashDiscountDays: 'Cash discount Days',
                cashDiscountPercents: 'Cash discount Percents',
                dueDateDays: 'Due date Days',
                customerDiscount: 'Customer discount',
            },
            additionalSection: {
                title: 'Additional information',
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',

                tags: 'Tags',
                birthday: 'Birthday',
                formDescription: 'Description',
            },
            defaultSettings: {
                title: 'Default Settings',
                taxRule: 'Tax Rate',
                paymentTerms: 'Payment Terms',
                salesDefaults: 'Sales Defaults',
                salesDefaultsDescription:
                    'Defaults can be overridden on individual invoices, quotes, and receive money',
                purchasesDefaults: 'Purchases Defaults',
                purchasesDefaultsDescription:
                    'Defaults can be overridden on individual spend money, bills and purchase orders',
            },
        },

        cannotDelete: {
            title: 'Deletion not possible',
            description:
                'Deletion is not possible, because at least one object is linked.',
            linkedDocuments: 'Linked documents: {msg}',
            invoiceNo: 'Invoice no. {number}',
            andMoreText: ' and more',
        },

        addPersonModal: {
            title: 'Add person',
            description:
                'Please select an existing person or create a new person for the organisation Org A. Hint: You can add multiple persons at once. Use the search function to find the right person.',
            messages: {
                success:
                    'Selected people have been assigned to this organisation',
            },
        },
    },
}
