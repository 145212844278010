<template>
    <data-grid
        :data-source="mails"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="$t('contacts.detail.mails.emptyText')"
        :empty-sub="$t('contacts.detail.mails.emptySubText')"
        identify-field="id"
        @row-click="redirectToMail"
    >
        <template #column-dateTime="{ item }">
            <p class="text-gray-900">
                {{ humanTimeFormat(item.properties.at) }}
            </p>
        </template>

        <template #column-system-mails="{ item }">
            <div>
                <p class="text-gray-900">
                    {{
                        $t('contacts.detail.mails.to', {
                            email: item.properties.to,
                        })
                    }}
                </p>
                <p>
                    <span class="text-gray-900">{{
                        $t(`contacts.detail.mails.actions.${item.event}`)
                    }}</span>
                    -
                    <span>{{
                        $t(`contacts.detail.mails.subject`, {
                            sender: item.properties.from,
                            subject: item.description,
                        })
                    }}</span>
                </p>
            </div>
        </template>
    </data-grid>
</template>

<script setup>
const { t } = useI18n()
const router = useRouter()
const emitter = useEmitter()
const route = useRoute()
import { humanTimeFormat } from '@tenant/utils/day'

const { execute, loading: loadingSearch } = useApi(
    `/api/contacts/{id}/mail-log-activities`,
    'GET'
)

const mails = ref([])

const columns = [
    {
        property: 'system-mails',
        label: t('contacts.detail.mails.systemMails'),
    },
    {
        property: 'dateTime',
        label: t('contacts.detail.mails.date'),
    },
]

onMounted(() => {
    emitter.emit('set-loading', true)
    execute({
        params: { id: route.params.id },
    })
        .then((res) => {
            mails.value = res
        })
        .finally(() => {
            emitter.emit('set-loading', false)
        })
})
const redirectToMail = (id) => {
    router.push({ name: 'contacts.detail.mail.detail', params: { id } })
}
</script>
