<template>
    <div class="flex h-screen overflow-y-scroll border-t px-6">
        <div v-if="contact" class="w-full">
            <div v-for="item in feeds" :key="item.id">
                <feed-item
                    v-if="item.log_name === 'default'"
                    :causer="item.causer"
                    :action="item.description"
                    :subject-id="item.subject_id"
                    :subject-type="item.subject_type"
                    :properties="item.properties"
                    :contact="contact"
                    :subject="item.subject"
                    :old-attributes="item.properties.old"
                    :new-attributes="item.properties.attributes"
                    :event="item.event"
                    :created-at="item.created_at"
                    snippet="contacts"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const route = useRoute()
const emitter = useEmitter()

const { execute } = useApi(`/api/contacts/{id}/feed-histories`, 'GET')

const feeds = ref([])
const contact = ref(null)

onMounted(async () => {
    emitter.emit('set-loading', true)
    execute({
        params: { id: route.params.id },
    })
        .then((res) => {
            feeds.value = res.logs
            contact.value = res.contact
        })
        .finally(() => {
            emitter.emit('set-loading', false)
        })
})

watch(
    () => route.params.id,
    () => {
        if (route.name !== 'contacts.detail.feed-history') {
            return
        }

        execute({
            params: { id: route.params.id },
        })
            .then((res) => {
                feeds.value = res.logs
                contact.value = res.contact
            })
            .finally(() => {
                emitter.emit('set-loading', false)
            })
    }
)
</script>
