<template>
    <div class="flex h-full" v-if="contact">
        <contacts-sidebar-list :contact="contact" />
        <div class="flex grow flex-col overflow-auto">
            <base-heading class="items-center px-6 py-4" :title="heading">
                <template #right>
                    <div class="flex justify-end gap-3">
                        <base-button
                            v-if="$acl.can('update_contacts')"
                            size="md"
                            variant="default"
                            icon="line-icons:general:edit-01"
                            @click="onEdit"
                        >
                            {{ $t('contacts.editPerson') }}
                        </base-button>
                    </div>
                </template>
            </base-heading>

            <contacts-tabs
                v-if="contact"
                class="mt-3 h-full overflow-auto"
                :contact="contact"
            />
        </div>
    </div>
</template>

<script setup>
import { getContactName } from '@tenant/utils/helper'

const router = useRouter()
const route = useRoute()
const emitter = useEmitter()
const { useApiGet } = useApiFactory('contacts')

const onEdit = () => {
    router.push({ name: 'contacts.edit', params: { id: route.params.id } })
}

const { execute: executeGet, result: contact } = useApiGet()
onMounted(async () => {
    try {
        emitter.emit('set-loading', true)
        await executeGet(route.params.id)
    } finally {
        emitter.emit('set-loading', false)
    }
})

const heading = computed(() => {
    return getContactName(unref(contact))
})

useHead({
    title: heading,
})

watch(
    () => route.params.id,
    async (id) => {
        if (id) {
            try {
                emitter.emit('set-loading', true)
                await executeGet(route.params.id)
            } finally {
                emitter.emit('set-loading', false)
            }
        }
    }
)
</script>
