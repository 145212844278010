<template>
    <base-content-tabs
        variant="horizontal"
        :items="tabs"
        tab-style="underlined"
        :has-margin="false"
        default="invoices"
        @change-tab="onChangeTab"
        class="items-baseline"
    />

    <keep-alive>
        <contacts-detail-transactions-invoices
            v-if="activeTabName === 'invoices'"
        />
    </keep-alive>

    <keep-alive>
        <contacts-detail-transactions-expenses
            v-if="activeTabName === 'expenses'"
        />
    </keep-alive>

    <keep-alive>
        <contacts-detail-transactions-bills v-if="activeTabName === 'bills'" />
    </keep-alive>

    <keep-alive>
        <contacts-detail-transactions-credit-notes
            v-if="activeTabName === 'creditNotes'"
        />
    </keep-alive>
</template>

<script setup>
const { t } = useI18n()
const acl = useAcl()
const tabs = [
    ...(acl.can('view_invoices')
        ? [
              {
                  label: t('contacts.transactions.invoices'),
                  name: 'invoices',
              },
          ]
        : []),
    ...(acl.can('view_expenses')
        ? [
              {
                  label: t('contacts.transactions.expenses'),
                  name: 'expenses',
              },
          ]
        : []),
    ...(acl.can('view_bills')
        ? [
              {
                  label: t('contacts.transactions.bills'),
                  name: 'bills',
              },
          ]
        : []),
    ...(acl.can('view_credit_notes')
        ? [
              {
                  label: t('contacts.transactions.creditNotes'),
                  name: 'creditNotes',
              },
          ]
        : []),
]

const activeTabName = ref(tabs.length ? tabs[0].name : null)
const onChangeTab = ({ name }) => {
    activeTabName.value = name
}
</script>
