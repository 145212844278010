<template>
    <div>
        <div class="flex items-center justify-between p-2.5">
            <contacts-date-dropdown @set-dates="setDates" />

            <!-- Actions -->
            <div class="flex gap-2.5">
                <!-- Print -->
                <base-tooltip
                    dark
                    hover
                    :content="$t('contacts.detail.statement.printTooltip')"
                >
                    <base-button variant="default" size="sm" @click="onPrint">
                        <base-icon
                            name="line-icons:media-&-devices:printer"
                            variant="inherit"
                        />
                    </base-button>
                </base-tooltip>

                <!-- File -->
                <base-tooltip
                    dark
                    hover
                    :content="$t('contacts.detail.statement.PDFTooltip')"
                >
                    <base-button
                        variant="default"
                        size="sm"
                        @click="onDownload"
                    >
                        <base-icon
                            name="line-icons:files:file-06"
                            variant="inherit"
                        />
                    </base-button>
                </base-tooltip>

                <!-- Send -->
                <base-button size="sm" @click="onSendEmail">
                    <base-icon
                        name="line-icons:communication:mail-01"
                        variant="inherit"
                    />
                    {{ $t('contacts.detail.statement.sendMailButton') }}
                </base-button>
            </div>
        </div>

        <!-- Titles -->
        <div class="mb-6 py-2.5 text-center">
            <h2 class="mb-2.5 text-lg font-normal">
                {{
                    $t('contacts.detail.statement.headline', {
                        name: heading,
                    })
                }}
            </h2>
            <p class="text-xs">
                {{ startDate.getDate() }}
                {{ startDate.toLocaleString('default', { month: 'long' }) }}
                <template
                    v-if="startDate.getFullYear() != endDate.getFullYear()"
                >
                    {{ startDate.getFullYear() }}
                </template>
                -
                {{ endDate.getDate() }}
                {{ endDate.toLocaleString('default', { month: 'long' }) }},
                {{ endDate.getFullYear() }}
            </p>
        </div>

        <!-- File document -->
        <div class="statement-view mb-6" v-html="statementView" />

        <!-- Send email modal -->
        <contacts-statement-send-email-modal
            :title="
                $t('contacts.detail.statement.sendEmail.title', {
                    name: heading,
                })
            "
            :start-date="startDate"
            :end-date="endDate"
            :loading="isSendingStatement"
            :show="showSendEmailModal"
            :contact="contact"
            @modal-close="showSendEmailModal = false"
            @modal-confirm="onSendStatement"
        />
    </div>
</template>

<script setup>
import { exportPDF, getContactName, printDocument } from '@tenant/utils/helper'
import { dayjsFormat } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const route = useRoute()

// Dates
const startDate = ref(new Date())
const endDate = ref(new Date())

const setDates = (start, end) => {
    startDate.value = start
    endDate.value = end
}

/**
 * Print
 */
const onPrint = () => {
    printDocument(base64PdfContent.value)
}

/**
 * Download pdf
 */
const onDownload = () => {
    exportPDF('statement.pdf', base64PdfContent.value)
}

/**
 * Send email
 */
const showSendEmailModal = ref(false)

const onSendEmail = () => {
    showSendEmailModal.value = true
}

/**
 * Get current contact name
 */
const { useApiGet } = useApiFactory('contacts')

const { execute: executeGet, result: contact } = useApiGet()
const { execute: getStatement } = useApi(`/api/contacts/{id}/statement`, 'GET')
const { execute: sendStatement, loading: isSendingStatement } = useApi(
    `/api/contacts/{id}/send-statement`,
    'POST'
)

const base64PdfContent = ref(null)
const statementView = ref(null)
watchEffect(async () => {
    let queries = {}

    if (startDate.value && endDate.value) {
        queries = {
            from_date: dayjsFormat(startDate.value, DATE_SERVER_FORMAT),
            to_date: dayjsFormat(endDate.value, DATE_SERVER_FORMAT),
        }
    }

    const { content, view } = await getStatement({
        params: {
            id: route?.params?.id,
        },
        queries,
    })

    base64PdfContent.value = content
    statementView.value = view
})

onMounted(async () => {
    await executeGet(route?.params?.id)
})

const heading = computed(() => {
    return getContactName(unref(contact))
})

const emitter = useEmitter()
const { successNotify } = useNotification()
const { t } = useI18n()
const { handleSubmit, setErrors } = useForm()
const onSendStatement = handleSubmit(async (values) => {
    try {
        await sendStatement({
            params: {
                id: route?.params?.id,
            },
            data: {
                ...values,
                from_date: dayjsFormat(startDate.value, DATE_SERVER_FORMAT),
                to_date: dayjsFormat(endDate.value, DATE_SERVER_FORMAT),
            },
        })

        showSendEmailModal.value = false
        successNotify({
            text: t('contacts.detail.statement.sendEmail.successMessage'),
        })

        emitter.emit('update-badges')
    } catch ({ errors }) {
        setErrors(errors)
    }
})
</script>

<style lang="scss">
.statement-view {
    min-width: 595px;
    max-width: 785px;
    aspect-ratio: 1 / 1.41;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
        0 1px 3px 0 rgba(16, 24, 40, 0.1);

    .statement-container {
        padding: 50px 35px 25px 35px;
    }

    .statement-footer {
        position: absolute;
        padding: 0 50px;
    }
}
</style>
