<template>
    <div class="flex w-full">
        <base-loading v-if="state.loading" class="flex w-full justify-center" />

        <base-card v-else class="w-full">
            <contacts-chart-view
                v-model="chartData.date"
                color="#7F56D9"
                :heading="$t('contacts.detail.overview.chart.heading')"
                :description="$t('contacts.detail.overview.chart.description')"
                :loading="chartData.loading"
                :labels="chartData.labels"
                :income="chartData.income"
                :expenses="chartData.expenses"
            >
                <template #bottom="{ date }">
                    <div
                        class="5 flex flex-col gap-2"
                        v-if="date && contact?.currency"
                    >
                        <div>
                            {{
                                $t('contacts.detail.overview.income.total', {
                                    date: $t(
                                        `contacts.detail.overview.dateOptions.${date}`
                                    ),
                                })
                            }}
                            -
                            <span class="font-semibold">
                                {{
                                    $filters.symbolCurrency(
                                        chartData.totalIncome,
                                        contact?.currency
                                    )
                                }}
                            </span>
                        </div>

                        <div>
                            {{
                                $t('contacts.detail.overview.expense.total', {
                                    date: $t(
                                        `contacts.detail.overview.dateOptions.${date}`
                                    ),
                                })
                            }}
                            -
                            <span class="font-semibold">
                                {{
                                    $filters.symbolCurrency(
                                        chartData.totalExpenses,
                                        contact?.currency
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </template>
            </contacts-chart-view>
        </base-card>
    </div>
</template>

<script setup>
import { TRANSACTION_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    },
})

const state = reactive({
    loading: true,
})

const chartData = ref({
    date: null,
    loading: true,
    labels: [],
    values: [],
    income: [],
    expenses: [],
    currency: null,
    totalIncome: 0,
    totalExpenses: 0,
})

const { execute } = useApi(`/api/contacts/{id}/chart`, 'GET')

onMounted(() => {
    loadSummary()
})

watch(
    () => props.contact.id,
    () => {
        loadSummary()
    }
)

watch(
    () => chartData.value.date,
    (date) => {
        getChartData(date)
    }
)

const loadSummary = async () => {
    try {
        state.loading = true
        await getChartData()
    } finally {
        state.loading = false
    }
}

const getChartData = async (type = null) => {
    try {
        chartData.value = {
            ...chartData.value,
            loading: true,
        }

        const {
            labels,
            income,
            expenses,
            currency,
            totalIncome,
            totalExpenses,
        } = await execute({
            params: {
                id: props.contact.id,
            },
            queries: {
                type,
                transaction_type: TRANSACTION_TYPE.INCOME,
            },
        })

        chartData.value = {
            ...chartData.value,
            labels: labels.map((i) => i.name),
            income,
            expenses,
            currency,
            totalIncome,
            totalExpenses,
        }
    } finally {
        chartData.value = {
            ...chartData.value,
            loading: false,
        }
    }
}
</script>
