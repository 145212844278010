<template>
    <bill-grid
        :queries="contactQueries"
        :replace-query="false"
        compact
        @bills-total="onTotalReceived"
        class="rounded-none"
    >
        <template #empty>
            <div class="flex gap-0.5">
                <p class="text-xs text-gray-700">
                    {{
                        $t('contacts.transactions.noResults', {
                            type: $t('contacts.transactions.bills'),
                        })
                    }}
                </p>
                <p class="text-xs text-gray-700">-</p>
                <p
                    v-if="$acl.can('create_bills')"
                    class="cursor-pointer text-xs text-primary-700"
                    @click="redirectToCreate"
                >
                    {{ $t('contacts.transactions.addNew') }}
                </p>
            </div>
        </template>
    </bill-grid>
</template>

<script setup>
const route = useRoute()
const contactQueries = computed(() => {
    return {
        'filter[contact_id]': route.params.id,
    }
})

const router = useRouter()
const redirectToCreate = () => {
    router.push({
        name: 'document-extraction',
    })
}

const total = ref(0)
const onTotalReceived = (value) => {
    total.value = value
}
</script>
