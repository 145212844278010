<template>
    <form-date-dropdown
        @set-dates="setDates"
        @set-date-range-key="setDateRangeKey"
    />
</template>

<script setup>
const emit = defineEmits(['set-dates', 'set-date-range-key'])

const setDateRangeKey = (key) => {
    emit('set-date-range-key', key)
}

const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

const startDate = ref(null)
const endDate = ref(null)
const setDates = (start, end) => {
    startDate.value = formatDate(start)
    endDate.value = formatDate(end)

    emit('set-dates', start, end)
}
</script>
