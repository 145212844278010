<template>
    <base-section
        :title="$t('contacts.form.additionalSection.title')"
        :description="$t('contacts.form.additionalSection.description')"
    >
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <!-- TODO: AC-337 Tag component-->
                    <!-- <form-tags-input-->
                    <!--     v-model="tags"-->
                    <!--     label="contacts.form.additionalSection.tags"-->
                    <!--     :error-message="errorTags"-->
                    <!--     :disabled="disabled"-->
                    <!-- />-->
                </form-control-group-row>

                <form-control-group-row v-if="isPersonContact">
                    <form-date-picker
                        single
                        v-model="birthday"
                        label="contacts.form.additionalSection.birthday"
                        :error-message="errorBirthday"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-rich-text-editor
                        v-model="description"
                        label="contacts.form.additionalSection.formDescription"
                        :error-message="errorDescription"
                        :disabled="disabled"
                    />
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'
import { computed } from 'vue'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: contactRelationshipType } = useField('contact_type')
// TODO: AC-337 Tag component
// const { value: tags, errorMessage: errorTags } = useField('tags')
const { value: birthday, errorMessage: errorBirthday } = useField('birthday')
const { value: description, errorMessage: errorDescription } =
    useField('description')

const isPersonContact = computed(() => {
    return contactRelationshipType.value === CONTACT_RELATIONSHIP_TYPES.person
})
</script>
