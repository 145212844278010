<template>
    <base-button
        variant="default"
        icon="line-icons:general:filter-lines"
        @click="toggleFilterMenu(true)"
    >
        <span class="whitespace-nowrap">
            <span>{{ $t('general.filters') }}</span>
            <span v-if="filterCount"> ({{ filterCount }})</span>
        </span>
    </base-button>

    <slide-out-menu-filters
        :show="showFilterMenu"
        @close="toggleFilterMenu(false)"
        @reset="onResetFilters"
    >
        <base-section vertical hide-info-block>
            <template #right>
                <form-control-group>
                    <form-control-group-row>
                        <form-filter-relationship-type-select
                            label="contacts.form.generalSection.contactTypes.type"
                            :person-filter="filterValues.person"
                            :organisation-filter="filterValues.organisation"
                            @update:modelValue="onChangeRelationshipType"
                        />
                    </form-control-group-row>
                </form-control-group>
            </template>
        </base-section>
    </slide-out-menu-filters>
</template>

<script setup>
import { ref } from 'vue'
import { useFilters } from '@tenant/composables'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

const queries = {
    person: 'filter.people_id',
    organisation: 'filter.organisation_id',
}

const { filterValues, filterCount, updateFilters, resetFilters } =
    useFilters(queries)

const showFilterMenu = ref(false)
const toggleFilterMenu = (value) => (showFilterMenu.value = value)

const onChangeRelationshipType = async (value) => {
    if (value === CONTACT_RELATIONSHIP_TYPES.person) {
        return updateFilters({
            [queries.organisation]: null,
            [queries.person]: ['<>', null],
        })
    }

    if (value === CONTACT_RELATIONSHIP_TYPES.organisation) {
        return updateFilters({
            [queries.person]: null,
            [queries.organisation]: ['<>', null],
        })
    }

    return updateFilters({
        [queries.person]: null,
        [queries.organisation]: null,
    })
}

const onResetFilters = () => {
    resetFilters().then(() => {
        showFilterMenu.value = false
    })
}
</script>
