<template>
    <div class="flex border-b py-4">
        <base-avatar :user="causer" />
        <div class="pl-3 text-sm">
            <div>
                {{ causer?.full_name }}
                <span class="ml-2 text-xs text-gray-500">{{ timeAgo }}</span>
            </div>
            <div class="text-gray-500" v-html="renderHtml"></div>
            <div v-if="isUpdatedAction">
                <div class="rounded bg-white">
                    <div class="flex items-center justify-between">
                        <button
                            @click="showDetails = true"
                            type="button"
                            class="border-1 relative inline-flex items-center rounded-lg border border-transparent bg-transparent text-xs text-primary-700 outline-none transition-all duration-150 hover:text-primary-800 disabled:pointer-events-none disabled:opacity-50"
                        >
                            <span class="flex items-center gap-3">
                                {{
                                    $t('contacts.feedHistory.btn_view_changes')
                                }}
                            </span>
                            <feed-item-view-details-modal
                                :show="showDetails"
                                :causer-name="causer?.full_name"
                                :old-attributes="oldAttributes"
                                :new-attributes="newAttributes"
                                :subject-model-name="subjectModelName"
                                @modal-close="showDetails = false"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getContactName } from '@tenant/utils/helper'

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
    causer: {
        type: Object,
        default: null,
    },

    action: {
        type: String,
        required: true,
    },

    subjectId: {
        type: String,
        required: true,
    },

    subjectType: {
        type: String,
        required: true,
    },

    properties: {
        type: Object,
        required: true,
    },

    contact: {
        type: Object,
        required: false,
    },

    subject: {
        type: Object,
        required: false,
    },

    oldAttributes: {
        type: Object,
        required: false,
    },

    newAttributes: {
        type: Object,
        required: false,
    },

    event: {
        type: String,
        required: true,
    },

    snippet: {
        type: String,
        required: true,
    },

    createdAt: {
        type: String,
        required: true,
    },
})

const showDetails = ref(false)

const camelCaseToSnakeCase = (inputString) => {
    return inputString.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

const renderUrl = (href, text) => {
    if (!props.subject) {
        return text
    }

    return `<a href="${href}" class="text-primary-700">${text}</a>`
}

const renderContactName = (text) => {
    return `<span class="text-primary-700">${text}</span>`
}

const subjectModelName = computed(() => {
    const parts = props.subjectType.split('\\')
    return parts[parts.length - 1]
})

const getModelNameFromSaleDocumentContact = () => {
    if (subjectModelName.value !== 'SaleDocumentContact') {
        return {}
    }

    let saleDocument = props.contact.invoices.find(
        (saleDocument) => saleDocument.id === props.subject.sale_document_id
    )
    if (!saleDocument) {
        saleDocument = props.contact.estimates.find(
            (saleDocument) => saleDocument.id === props.subject.sale_document_id
        )
    }

    const typeableTypes = saleDocument?.typeable_type.split('\\')
    return {
        saleDocument,
        typeableType: typeableTypes
            ? typeableTypes[typeableTypes.length - 1]
            : null,
    }
}

const linkDetailUrl = computed(() => {
    if (!props.properties.is_show_url) {
        return null
    }

    switch (subjectModelName.value) {
        case 'SaleDocumentContact': {
            const { saleDocument, typeableType } =
                getModelNameFromSaleDocumentContact()
            if (typeableType === 'Invoice') {
                return {
                    name: 'invoices.edit',
                    params: { id: saleDocument?.typeable_id },
                }
            } else if (typeableType === 'Estimate') {
                return {
                    name: 'estimates.edit',
                    params: { id: saleDocument?.typeable_id },
                }
            }

            return {
                name: 'invoices.edit',
                params: { id: props.subject?.typeable_id },
            }
        }
        case 'Invoice':
            return {
                name: 'invoices.edit',
                params: { id: props.subjectId },
            }
        case 'Estimate':
            return {
                name: 'estimates.edit',
                params: { id: props.subjectId },
            }
        case 'Bill':
            return {
                name: 'document-extraction.inbox.edit',
                params: { id: props.subjectId },
            }
        case 'Expense':
            return {
                name: 'purchases.expense.edit',
                params: { id: props.subjectId },
            }
        case 'Document':
            return {
                name: 'documents.overview',
                params: { id: props.subject?.uuid },
            }
        default:
            return {}
    }
})

const getSubjectName = computed(() => {
    if (!props.subject) {
        let subject = ''
        if (props.event === 'deleted') {
            subject = props.properties.old
        } else {
            subject = props.properties.attributes
        }

        switch (subjectModelName.value) {
            case 'Invoice':
                return subject.sale_document.number
            case 'Estimate':
                return subject.sale_document.number
            case 'Bill':
                return subject.document_number
            case 'Expense':
                return subject.reference
            default:
                return ''
        }
    }

    switch (subjectModelName.value) {
        case 'SaleDocumentContact': {
            let saleDocument = props.contact.invoices.find(
                (saleDocument) =>
                    saleDocument.id === props.subject.sale_document_id
            )
            if (!saleDocument) {
                saleDocument = props.contact.estimates.find(
                    (saleDocument) =>
                        saleDocument.id === props.subject.sale_document_id
                )
            }

            return saleDocument?.number
        }
        case 'Document':
            return props.subject.parent_id === '1'
                ? heading.value
                : props.subject.name
        case 'Invoice':
            return props.subject.sale_document.number
        case 'Estimate':
            return props.subject.sale_document.number
        case 'Bill':
            return props.subject.document_number
        case 'Expense':
            return props.subject.reference
        default:
            return ''
    }
})

const heading = computed(() => {
    return getContactName(unref(props.contact))
})

const renderHtml = computed(() => {
    const model = camelCaseToSnakeCase(subjectModelName.value)
    let fullPath = ''
    if (linkDetailUrl.value) {
        const route = router.resolve(linkDetailUrl.value)
        if (route.fullPath) {
            fullPath = route.href
        } else {
            console.error(
                `Route with name ${linkDetailUrl.value.name} not found`
            )
        }
    }

    const url = renderUrl(fullPath, getSubjectName.value)
    const contact = renderContactName(heading.value)

    const { typeableType } = getModelNameFromSaleDocumentContact()

    return t(`${props.snippet}.feedHistory.${model}.${props.action}`, {
        url: url,
        contact: contact,
        pdf: 'PDF',
        ...props.properties,
        type: typeableType,
    })
})

const timeAgo = computed(() => {
    const currentDate = new Date()
    const providedDate = new Date(props.createdAt)
    const timeDifference = currentDate - providedDate

    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (seconds < 5) {
        return t('contacts.feedHistory.time_ago.just_now')
    } else if (seconds < 60) {
        return t('contacts.feedHistory.time_ago.seconds_ago', {
            count: seconds,
        })
    } else if (minutes < 60) {
        return t('contacts.feedHistory.time_ago.minutes_ago', {
            count: minutes,
        })
    } else if (hours < 24) {
        return t('contacts.feedHistory.time_ago.hours_ago', { count: hours })
    } else {
        return t('contacts.feedHistory.time_ago.days_ago', { count: days })
    }
})

const isUpdatedAction = computed(() => {
    return props.newAttributes && props.oldAttributes
})
</script>
