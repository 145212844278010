<template>
    <form-text-input
        v-model="customerNumber"
        label="contacts.customerNumber"
        :error-message="errorCustomerNumber"
    />
</template>

<script setup>
import { onMounted } from 'vue'
import { useField } from 'vee-validate'
import { useApi } from '@tenant/composables'

const props = defineProps({
    autoFill: {
        type: Boolean,
        default: false,
    },
})

const { execute } = useApi(
    '/api/sequence-settings/generate-number/contact',
    'GET'
)

const {
    value: customerNumber,
    errorMessage: errorCustomerNumber,
    setValue,
} = useField('customer_number')

onMounted(() => {
    if (!props.autoFill) {
        return
    }

    execute().then((response) => {
        setValue(response)
    })
})
</script>
