<template>
    <base-section
        :title="$t('contacts.form.contactSection.title')"
        :description="$t('contacts.form.contactSection.description')"
    >
        <template #right>
            <form-field-array
                :fields="phoneNumbers"
                :label-add="$t('contacts.form.contactSection.addPhone')"
                @on-remove-item="removePhone"
                @on-add-item="pushPhone({ phone: null, type: null })"
            >
                <template #default="{ item: { value, isFirst, key } }">
                    <form-control-group-row class="items-start">
                        <form-field-array-error
                            :name="`phone[${key}].phone`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-model="value.phone"
                                :error-message="errorMessage"
                                :label="
                                    isFirst &&
                                    'settingsCompany.contact.phoneLabel'
                                "
                            />
                        </form-field-array-error>
                        <form-single-select
                            v-model="value.type"
                            :label="isFirst && 'general.type'"
                            :options="contactDetailTypes"
                        />
                    </form-control-group-row>
                </template>
            </form-field-array>
            <hr class="my-4" />
            <form-field-array
                :fields="emails"
                :label-add="$t('contacts.form.contactSection.addEmail')"
                @on-remove-item="removeEmail"
                @on-add-item="pushEmail({ email: null, type: null })"
            >
                <template #default="{ item: { key, value, isFirst } }">
                    <form-control-group-row class="items-start">
                        <form-field-array-error
                            :name="`email[${key}].email`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-model="value.email"
                                :error-message="errorMessage"
                                :label="
                                    isFirst &&
                                    'settingsCompany.contact.emailLabel'
                                "
                            />
                        </form-field-array-error>
                        <form-single-select
                            v-model="value.type"
                            :label="isFirst && 'general.type'"
                            :options="contactDetailTypes"
                        />
                    </form-control-group-row>
                </template>
            </form-field-array>
            <hr class="my-4" />
            <form-field-array
                :fields="domains"
                :label-add="$t('contacts.form.contactSection.addWeb')"
                @on-remove-item="removeDomain"
                @on-add-item="pushDomain({ web: null, type: null })"
            >
                <template #default="{ item: { value, isFirst, key } }">
                    <form-control-group-row class="items-start">
                        <form-field-array-error
                            :name="`web[${key}].web`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-model="value.web"
                                :error-message="errorMessage"
                                :label="
                                    isFirst &&
                                    'settingsCompany.contact.webLabel'
                                "
                            />
                        </form-field-array-error>
                        <form-single-select
                            v-model="value.type"
                            :label="isFirst && 'general.type'"
                            :options="contactDetailTypes"
                        />
                    </form-control-group-row>
                </template>
            </form-field-array>
        </template>
    </base-section>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { contactDetailTypes } = useContactDetailTypes()

const {
    remove: removePhone,
    push: pushPhone,
    fields: phoneNumbers,
} = useFieldArray('phone')
const {
    remove: removeEmail,
    push: pushEmail,
    fields: emails,
} = useFieldArray('email')
const {
    remove: removeDomain,
    push: pushDomain,
    fields: domains,
} = useFieldArray('web')
</script>
