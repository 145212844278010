const navigation = [
    {
        id: 'sas-contacts',
        path: 'contacts',
        label: 'contacts.sidebar.label',
        icon: 'line-icons:images:image-user',
        position: 20,
        permission: 'view_contacts',
    },
]

export default navigation
