<template>
    <base-sticky-heading
        :title="$t('contacts.add.title')"
        :description="$t('contacts.add.description')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingCreatePerson || loadingCreateOrg"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-6">
            <contacts-section-general is-creating-form />
            <contacts-section-tabs />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const { successNotify } = useNotification()

const { setValues, setErrors, handleSubmit } = useForm()
const { loading: loadingCreatePerson, execute: executeCreatePerson } =
    useApiFactory('contacts/person').useApiCreate()
const { loading: loadingCreateOrg, execute: executeCreateOrg } = useApiFactory(
    'contacts/organisation'
).useApiCreate()

useHead({ title: t('contacts.add.title') })

onMounted(() => {
    setValues({
        contact_type:
            route.query?.type ?? CONTACT_RELATIONSHIP_TYPES.organisation,
    })
})

const onCancel = () => {
    router.push({
        name: 'contacts',
    })
}

const onSubmit = handleSubmit(async (values) => {
    try {
        let contact

        if (values.contact_type === CONTACT_RELATIONSHIP_TYPES.person) {
            contact = await executeCreatePerson(values)
        } else {
            contact = await executeCreateOrg(values)
        }

        await router.push({
            name: 'contacts.detail',
            params: { id: contact.id },
        })

        // Show a notification on success
        successNotify({
            title: t('general.success'),
            text: t('contacts.add.addSuccess'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
})

const { defaultCurrency } = useCurrencies()
const { value: currency } = useField('currency_id')

watch(
    () => defaultCurrency.value,
    (value) => {
        if (!value) {
            return
        }

        if (!currency.value) {
            setValues({
                currency_id: value.value,
            })
        }
    },
    { immediate: true }
)
</script>
