<template>
    <expenses-grid
        :replace-query="false"
        :queries="contactQueries"
        :selectable="false"
        :columns="columns"
        compact
        @expenses-total="onTotalReceived"
        class="rounded-none"
    >
        <template #empty>
            <div class="flex gap-0.5">
                <p class="text-xs text-gray-700">
                    {{
                        $t('contacts.transactions.noResults', {
                            type: $t('contacts.transactions.expenses'),
                        })
                    }}
                </p>
                <p class="text-xs text-gray-700">-</p>
                <p
                    v-if="$acl.can('create_expenses')"
                    class="cursor-pointer text-xs text-primary-700"
                    @click="redirectToCreate"
                >
                    {{ $t('contacts.transactions.addNew') }}
                </p>
            </div>
        </template>
    </expenses-grid>
</template>

<script setup>
const route = useRoute()
const contactQueries = computed(() => {
    return {
        'filter[contact_id]': route.params.id,
    }
})

const router = useRouter()
const redirectToCreate = () => {
    router.push({
        name: 'purchases.expense.create',
    })
}

const total = ref(0)
const onTotalReceived = (value) => {
    total.value = value
}

const { t } = useI18n()
const columns = [
    {
        property: 'date',
        label: t('purchasesExpense.list.columns.date'),
    },
    {
        property: 'expense',
        label: t('purchasesExpense.list.columns.expenseAccount'),
    },
    {
        property: 'vendor',
        label: t('purchasesExpense.list.columns.vendorName'),
    },
    {
        property: 'status',
        label: t('purchasesExpense.list.columns.status'),
    },
    {
        property: 'amount',
        label: t('purchasesExpense.list.columns.amount'),
    },
    {
        property: 'actions',
        label: ' ',
    },
]
</script>
