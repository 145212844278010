<template>
    <base-section :hide-info-block="true">
        <template #right>
            <form-control-group>
                <form-control-group-row v-if="isPersonContact">
                    <form-single-select
                        v-model="salutation"
                        label="user.salutation"
                        :options="salutations"
                        :error-message="errorSalutation"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="title"
                        label="general.title"
                        :error-message="errorTitle"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row v-if="isPersonContact">
                    <form-text-input
                        v-model="firstName"
                        label="contacts.form.generalSection.firstName"
                        :error-message="errorFirstName"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="lastName"
                        label="contacts.form.generalSection.lastName"
                        :error-message="errorLastName"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-if="isOrganisationContact"
                        v-model="organisationName"
                        label="contacts.form.generalSection.organisationName"
                        :error-message="errorOrganisationName"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="legalName"
                        label="contacts.legalName"
                        :error-message="errorLegalName"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-customer-number-input
                        :disabled="disabled"
                        :auto-fill="isCreatingForm"
                    />
                    <form-single-select
                        v-model="type"
                        label="contacts.form.generalSection.contactTypes.type"
                        :options="contactTypes"
                        :error-message="errorType"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row v-if="isPersonContact">
                    <form-text-input
                        v-model="organisation"
                        label="general.organisation"
                        :error-message="errorOrganisation"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="lineItem"
                        label="contacts.lineItem"
                        :error-message="errorLineItem"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-model="creditorNumber"
                        label="contacts.creditorNumber"
                        :error-message="errorCreditorNumber"
                        :disabled="disabled"
                    />
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
import { computed } from 'vue'
import { useField } from 'vee-validate'
import { useSalutations, useContactTypes } from '@tenant/composables'
import { CONTACT_RELATIONSHIP_TYPES } from '@tenant/utils/constants'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    isCreatingForm: {
        type: Boolean,
        default: false,
    },
})

const { value: contactRelationshipType } = useField('contact_type')
const { value: salutation, errorMessage: errorSalutation } =
    useField('salutation_id')
const { value: title, errorMessage: errorTitle } = useField('title')
const { value: firstName, errorMessage: errorFirstName } =
    useField('first_name')
const { value: lastName, errorMessage: errorLastName } = useField('last_name')
const { value: organisationName, errorMessage: errorOrganisationName } =
    useField('name')
const { value: legalName, errorMessage: errorLegalName } =
    useField('legal_name')
const { value: type, errorMessage: errorType } = useField('contact_type_id')
const { value: organisation, errorMessage: errorOrganisation } =
    useField('organisation')
const { value: lineItem, errorMessage: errorLineItem } = useField('line_item')
const { value: creditorNumber, errorMessage: errorCreditorNumber } = useField(
    'debtor_creditor_number'
)

const { salutations } = useSalutations()
const { contactTypes } = useContactTypes()

const isPersonContact = computed(() => {
    return contactRelationshipType.value === CONTACT_RELATIONSHIP_TYPES.person
})
const isOrganisationContact = computed(() => {
    return (
        contactRelationshipType.value ===
        CONTACT_RELATIONSHIP_TYPES.organisation
    )
})
</script>
