<template>
    <base-compact-list
        :items="contacts"
        :is-loading="isLoadingContacts"
        :initial-selected-item="contact"
        selectable
        @item-click="onSidebarItem"
        @load-more="onLoadMore"
        @item-check="onItemCheck"
        :initialize="initializeList"
    >
        <template #header="{ checkedItems }">
            <template v-if="checkedItems.length">
                <div class="flex items-center gap-2.5">
                    <span class="text-sm text-gray-700">
                        {{ $t('general.selectedText', checkedItems.length) }}
                    </span>
                </div>
            </template>

            <template v-else>
                <base-dropdown size="sm" variant="default" class="bg-white">
                    <template #btn-content>
                        {{ statusText }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange()
                                }
                            "
                        >
                            All contacts
                        </base-dropdown-item>

                        <base-dropdown-item
                            v-for="contactType in contactTypes"
                            :key="contactType.value"
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(contactType.value)
                                }
                            "
                        >
                            {{ contactType.label }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>

                <base-button
                    v-if="$acl.can('create_contacts')"
                    icon="line-icons:general:plus"
                    :label="$t('general.new')"
                    size="sm"
                    @click="goToContactCreate"
                />
            </template>
        </template>

        <template #item-content="{ item, clickItem }">
            <div class="w-full" @click="clickItem(item)">
                <p class="text-sm text-primary-600">
                    {{ item?.contact_name }}
                </p>
                <p class="pt-1 text-sm text-gray-700" v-if="defaultCurrency">
                    {{
                        $filters.currency(
                            item?.receivables,
                            defaultCurrency?.iso
                        )
                    }}
                </p>
            </div>
        </template>
    </base-compact-list>
</template>

<script setup>
import { isEqual, uniqBy } from 'lodash-es'
import { useContactTypes, useFilters } from '@tenant/composables'
import { useInfiniteQuery } from '@tanstack/vue-query'
const { defaultCurrency } = useCurrencies()

const props = defineProps({
    contact: {
        type: Object,
        default: () => null,
    },
})

const route = useRoute()
const router = useRouter()

// INFINITE SCROLL CONTACT LIST
const { useApiSearch } = useApiFactory('contacts')
const { execute } = useApiSearch({}, false)

const {
    data: contacts,
    isFetching: isLoadingContacts,
    fetchNextPage: fetchMoreAccounts,
    hasNextPage: hasMoreAccounts,
    refetch,
} = useInfiniteQuery({
    queryKey: ['contacts'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

const onLoadMore = () => {
    if (hasMoreAccounts.value) {
        fetchMoreAccounts()
    }
}

const onSidebarItem = (item) => {
    router.push({
        name: 'contacts.detail',
        params: { id: item.id },
        query: { ...route.query },
    })
}

const goToContactCreate = () => {
    router.push({ name: 'contacts.add' })
}

// FILTER CONTACTS
const queries = {
    type: 'filter.contact_type_id',
}
const { filterValues, updateFilter } = useFilters(queries)
const status = ref(null)
watch(
    () => filterValues.value,
    (value, oldValue) => {
        if (isEqual(value, oldValue)) return
        refetch()
    }
)
watch(
    () => props.contact,
    () => {
        refetch()
    }
)

const filterChange = (contactType = null) => {
    updateFilter(queries.type, contactType)
    status.value = contactType
}

onMounted(() => {
    status.value = filterValues.value.type
})

const { t } = useI18n()
const statusText = computed(() => {
    return (
        contactTypes.value.find((x) => x.value === status.value)?.label ??
        t('contacts.detail.allContacts')
    )
})
// END: FILTER CONTACTS

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}

const initializeList = ref(false)

const { contactTypes } = useContactTypes()
</script>
